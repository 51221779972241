import React from "react";
import Meta from "../../components/Meta";
import { useRouter } from "../../util/router";
import { Link } from "../../util/router";
import SbPackages from "./Temp.js/sbPackages";
import TpPackages from "./Temp.js/tpPackages";
import LifePackages from "./Temp.js/lifePackages";
import TestimonialsSection from "../../components/TestimonialsSection";
import InfoSection from "../../components/Custom/InfoSection";
import { useAuth } from "../../util/auth";

var tpImage = 'https://firebasestorage.googleapis.com/v0/b/insurace-agency-trainin-9mw02g.appspot.com/o/coverPhotos%2Ftp.png?alt=media&token=ab6b57e5-803d-40d6-88ac-27dd66b92e90'
var sbImage = 'https://firebasestorage.googleapis.com/v0/b/insurace-agency-trainin-9mw02g.appspot.com/o/coverPhotos%2Fsb.png?alt=media&token=8ab5c3b9-bd26-41f8-ad8e-948de1588b5f'
var lifeImage = 'https://firebasestorage.googleapis.com/v0/b/insurace-agency-trainin-9mw02g.appspot.com/o/coverPhotos%2Flife.png?alt=media&token=bc27bf89-d10a-4980-baad-fcd218c595eb'
function PackageInfoPage(props) {
  const auth = useAuth()
  const router = useRouter();
    const sbtestimonials = [
        {
        name: 'Jen',
        body: `This video is amazing. I showed it to my first client yesterday.
        When we were done I explained they had a term policy [which] was going to go up and they said, "I want a bucket." When I asked them how much they could afford, they said, "$1,200 a month."
        We ended up writing a $1200 a month 15 pay policy. The biggest one I've ever done. ON my first try. I'm a believer. Thank you for this tool.`,
        },
        {
        name: 'Art',
        body: `"Stairs and Buckets" is the best thing I have done for my team's production. They have sold over $200k in premium in the first half of this year. My team is really good at using this now and I can't imagine not having it, this really has changed the way we approach customers about life insurance. We highly endorse and recommend this to everyone in the business!`,
        },
        {
        name: 'Will',
        body: `I have struggled selling much besides term insurance with my clients until I came across this training. Now I sell larger whole life and universal life consistently. This has become an effective tool in my conversations with customers. Last year we traveled and sold over $120,000 in premium and I have only been an agent for 4 years now.`,
        },
        
    ]
  return (
    <>
      <Meta title="Packages" />
      <div className="container text-center">
        {(router.query.section === "sb")&&(<>

        <InfoSection photo = {sbImage} href={auth.user? `/pricing/plans`: `/auth/signup?next=/pricing/plans`} subtitle = "Stairs & Buckets 101"header = "Million Dollar Stairs and Buckets Start-Up" title="Try the video, webinar training, tools and guide risk free!" cta="Buy Now" perks = {["Save time and make $100,000 more using stairs and buckets.", "A repeatable, simple 4-minute video tool for consistently training your team."]}/>
        {/* <FrontHeader text = "Reasons to use Stairs and Buckets"/> */}
        <div className="mb-6">
        {/* <PerksList perks={sbperks}/> */}
        <div className="m">
        <SbPackages/>
        <TestimonialsSection testimonials={sbtestimonials}/>
        <div className="bg-white py-12">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
          <h1 className="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
          Your Shortcut to Life Insurance Success
          </h1>
          <div className="mt-10 grid max-w-xl grid-cols-1 gap-8 text-base/7 text-gray-700 lg:max-w-none lg:grid-cols-2">
            <div>
              <p className="text-start">
              Selling life insurance can be one of the most challenging tasks in the financial industry. It often involves breaking through misconceptions, explaining complex products, and connecting with customers on an emotional level to highlight the importance of coverage. Many people avoid thinking about life insurance because it forces them to confront uncomfortable topics, making it harder for agents to start meaningful conversations. But what if there was a way to simplify these discussions and make the process more approachable?
              </p>
              <p className="mt-8 text-start">
              That’s where our tool comes in. We’ve created a concise, 4.5-minute video that demystifies life insurance in a way that’s easy to understand for your potential customers. It explains the various types of life insurance policies—term, whole, and universal—in plain, digestible language, cutting through the technical jargon that often overwhelms people. This video is designed not just to inform but to build trust and open the door to deeper conversations about securing a financial future.
              </p>
            </div>
            <div>
              <p className="text-start">
              The beauty of this tool is that it does the heavy lifting for you. Instead of struggling to explain policies or losing prospects to confusion, you can leverage this video as an engaging and educational resource. It walks viewers through key concepts in a way that resonates, making it easier for them to see the value of life insurance and, most importantly, take action. By using the video, you can position yourself as a trusted advisor who provides clarity and solutions, boosting your credibility and confidence in your sales process.
              </p>
              <p className="mt-8 text-start">
              This video isn’t just a resource; it’s a game-changer for your business. By helping customers feel informed and empowered, you’re creating a smoother path to sales and fostering long-term relationships. Selling life insurance doesn’t have to be hard—let this video be the bridge that connects you with your customers and drives results.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
        </div>
        <Link to={auth.user? `/pricing/plans`: `/auth/signup?next=/pricing/plans`}>
        <button className="bg-green-700 m-4 w-3/4 text-5xl text-white rounded-lg p-6">Buy Now</button>
        </Link>
        </div>

        </>)}
        {(router.query.section === "tp")&&(<>
            <InfoSection photo={tpImage} href={auth.user? `/pricing/plans`: `/auth/signup?next=/pricing/plans`} subtitle = "Training Package & Manuals"  header = "Million Dollar Road Map" title="Agency Growth Made Simple: Our Training Bundle" cta="Buy Now" perks = {["Agency growth made simple: Equip your team with tools, skills, and playbooks for better sales.", "Sales Processes, Manuals, Scripts, Non-Compete + Templates"]}/>
            {/* <FrontHeader text = "Reasons to Use This Package"/> */}
            <TpPackages/>
            <div className="bg-white py-12">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
          <h1 className="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
          Even More: Take your Business to the Next Level
          </h1>
          <div className="mt-10 grid max-w-xl grid-cols-1 gap-8 text-base/7 text-gray-700 lg:max-w-none lg:grid-cols-2">
            <div>
              <p className="text-start">
              Selling life insurance and related financial products like Medicare can feel like an uphill battle. Not only do you need to educate customers on complicated policies, but you also have to juggle building trust, staying compliant, and refining your sales techniques. For many, this process can be overwhelming, leading to missed opportunities and stalled growth. That’s why we’ve developed an all-in-one solution to help you not only sell life insurance more effectively but also expand your toolkit to reach even greater heights.
              </p>
              <p className="mt-8 text-start">
              Our premium tier builds on the success of our 4.5-minute life insurance video by adding a comprehensive suite of resources tailored to your needs. It includes seven in-depth webinars that tackle common sales challenges, a slideshow specifically designed for selling Medicare policies, and a detailed training manual to sharpen your skills. These tools empower you to address a wide range of customer needs while boosting your own expertise and confidence. It’s everything you need to close more deals and grow your business.
              </p>
            </div>
            <div>
              <p className="text-start">
              But that’s not all. This tier also includes a non-compete agreement template and other valuable resources to protect your business and help you operate more securely. Whether you’re just starting out or looking to scale, these additional tools ensure that you’re covered from every angle, giving you the confidence to focus on what matters most: connecting with your customers. With our comprehensive package, you’ll have the support you need to handle even the toughest sales scenarios.
              </p>
              <p className="mt-8 text-start">
              By investing in this premium tier, you’re not just improving your ability to sell life insurance—you’re positioning yourself as a true expert and trusted advisor in your field. These resources are designed to work together seamlessly, helping you save time, reduce stress, and drive results. Don’t let complexity hold you back; with this robust toolkit, you’ll be equipped to take your sales to the next level and beyond.
              </p>
            </div>
          </div>
        </div>
      </div>
      </div>
            <Link to ={auth.user? `/pricing/plans`: `/auth/signup?next=/pricing/plans`}>
            <button className="bg-green-700 m-4 w-3/4 text-5xl text-white rounded-lg p-6"> Buy Now</button>
            </Link>
        </>)}

        {(router.query.section === "life")&&(<>
         <InfoSection photo = {lifeImage} href = {auth.user? `/pricing/plans`: `/auth/signup?next=/pricing/plans`} subtitle = "Includes All Current and Future Content"  header = "The Million-Dollar Membership" title="Lifetime Mastery and Exclusive Experiences: Your Gateway to Insurance Excellence" cta="Buy Now" perks = {["Join the Million Dollar Club for an unparalleled journey in insurance expertise.", "A one-time investment grants you lifetime access to all our current and future trainings, and exclusive networking events with top agents."]}/>
         {/* <FrontHeader text = "Unprecedented Value of the Million Dollar Lifetime Membership"/>
            <PerksList perks={lifeperks}/> */}
            <LifePackages/>

            <Link to = {auth.user? `/pricing/plans`: `/auth/signup?next=/pricing/plans`}>
            <button className="bg-green-700 m-4 w-3/4 text-5xl text-white rounded-lg p-6"> Buy Now</button>
            </Link>
        </>)}
        </div>
    </>
  );
}

export default PackageInfoPage;
