export default function SbPackages() {
    return (
      <div className="bg-white py-12 sm:py-12">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
            <p className="text-base/7 font-semibold text-green-700">Stairs and Buckets Package</p>
            <h1 className="mt-2 text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
              Pricing and Features
            </h1>
            <div className="mt-10 grid max-w-xl grid-cols-1 gap-8 text-base/7 text-gray-700 lg:max-w-none lg:grid-cols-2">
                <div className="border border-green-700 rounded-lg p-6 shadow-lg">
                    <h2 className="text-2xl font-bold tracking-tight text-green-800">Pricing</h2>
                    <p className="mt-4">
                    <strong>Monthly Plan:</strong> $59.99 per month<br />
                    <strong>Annual Plan:</strong> $599.99 per year
                    </p>
                <h2 className="mt-8 text-2xl font-bold tracking-tight text-green-800">Core Offer and Features</h2>
                <ul className="mt-4 space-y-4 text-start">
                  <li>
                    <strong>Key Tool:</strong> The Stairs and Buckets Video License (4.5 minutes long), a concise,
                    effective educational video that simplifies the life insurance conversation for clients.
                  </li>
                  <li>
                    <strong>Accessibility:</strong> Available in English, Spanish, Cantonese, and Mandarin to reach
                    diverse audiences.
                  </li>
                  <li>
                    <strong>Ease of Use:</strong> Designed to resonate with a wide range of customers, from teenagers to
                    highly educated professionals like engineers or doctors.
                  </li>
                </ul>
              </div>
              <div className="border border-green-700 rounded-lg p-6 shadow-lg">
                <h2 className="text-2xl font-bold tracking-tight text-green-800">Key Benefits</h2>
                <ul className="mt-4 space-y-4 text-start">
                  <li>
                    <strong>Empowers Teams:</strong> Helps agents train their team members, allowing them to take on a
                    larger share of the sales effort and reduce reliance on the agent.
                  </li>
                  <li>
                    <strong>Increased Revenue:</strong> Agents can increase their sales by $100,000 annually by
                    consistently using this process.
                  </li>
                  <li>
                    <strong>Risk-Free:</strong> Includes a 100% Money-Back Guarantee if canceled within 3 days.
                  </li>
                  <li>
                    <strong>Affordable:</strong> Priced to be accessible for new agents and team members, with a return
                    on investment achievable with just one sale per month.
                  </li>
                </ul>
  
                <h2 className="mt-8 text-2xl font-bold tracking-tight text-green-800">Package Includes</h2>
                <ul className="mt-4 space-y-4 text-start">
                  <li>
                    <strong>Stairs and Buckets Video License:</strong> Unlimited views and usage.
                  </li>
                  <li>
                    <strong>Training Webinar:</strong> A one-hour session teaching agents how to have effective client
                    conversations.
                  </li>
                  <li>
                    <strong>Eight-Step Guide:</strong> A step-by-step word script designed for quick onboarding and
                    implementation.
                  </li>
                  <li>
                    <strong>Online Platform Access:</strong> A centralized application to access the tool, analytics, and
                    accompanying training materials.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none my-8">
            <p className="text-base/7 font-semibold text-green-700">Stairs and Buckets Package</p>
            <h1 className="mt-2 text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                Detailed Features
            </h1>
            <div className="mt-10 grid max-w-xl grid-cols-1 gap-8 text-base/7 text-gray-700 lg:max-w-none lg:grid-cols-2">
                <div className="border border-green-700 rounded-lg p-6 shadow-lg">
                <h2 className="text-2xl font-bold tracking-tight text-green-800">Detailed Features</h2>
                <ul className="mt-4 space-y-4 text-start">
                    <li>
                    <strong>Eight-Step Guide:</strong> A simple, effective script that ensures quick ramp-up for agents, making it easy to adopt and implement the Stairs and Buckets process right away.
                    </li>
                    <li>
                    <strong>Team Functionality and Tracking:</strong>
                    <ul className="mt-2 ml-4 list-disc space-y-2">
                        <li>Agents can add team members, granting each their own login.</li>
                        <li>Team Training Metrics: Track how much each team member has trained and how frequently they use the sales process.</li>
                        <li>Accountability: Provides agents with critical data to ensure team members are engaged and utilizing the tools effectively.</li>
                    </ul>
                    </li>
                </ul>
                </div>

                <div className="border border-green-700 rounded-lg p-6 shadow-lg">
                <h2 className="text-2xl font-bold tracking-tight text-green-800">Ease of Use and Mastery</h2>
                <ul className="mt-4 space-y-4 text-start">
                    <li>
                    <strong>Quick Start:</strong> Requires only a couple of hours of training to implement.
                    </li>
                    <li>
                    <strong>Long-Term Mastery:</strong> Encourages prolonged, repeated use to make the process second nature.
                    </li>
                    <li>
                    <strong>Perfect for Novices:</strong> Takes new or inexperienced salespeople and equips them with a reliable process for selling higher premium life insurance policies.
                    </li>
                </ul>

                <h2 className="mt-8 text-2xl font-bold tracking-tight text-green-800">Unique Selling Proposition</h2>
                <ul className="mt-4 space-y-4 text-start">
                    <li>
                    <strong>Plug-and-Play Tools:</strong> Designed for immediate use, reducing the need for extensive training or customization.
                    </li>
                    <li>
                    <strong>Simplified Training:</strong> Focuses on delivering a ready-to-use tool rather than complex systems agents must learn and implement themselves.
                    </li>
                    <li>
                    <strong>Efficiency:</strong> Minimizes time spent learning and mastering the content, allowing agents and their teams to start making sales right away.
                    </li>
                </ul>
                </div>
            </div>
            </div>

        </div>
      </div>
    );
  }
  