import React, { useState } from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { useAuth } from "./../util/auth";
import PlanCard from "./Custom/PlanCard";
import { Link, useRouter } from "../util/router";
import { QuestionMarkCircleIcon, ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/24/outline";

const singleIds = ["sby", "sbm", "proc","procm", "tp","tpm", "life"]

const plans = [
  {
    id: "sby",
    name: "Million Dollar Stairs and Buckets Start-Up",
    price: "599.99",
    period: "per year",
    perks: [
      <>
        <strong>10&nbsp;</strong>Seats at your agency
      </>,
      <>
        <strong>Unlimited&nbsp;</strong> Video Sends
      </>,
      <>
       Team <strong>&nbsp;and&nbsp;</strong> Individual Analytics
      </>,
      <>
        <strong>4&nbsp;</strong> Different Languages
      </>,
      <>
      <strong>Free&nbsp;</strong> Training Webinar
    </>,
    ],
  },
  {
    id: "sbm",
    name: "Million Dollar Stairs and Buckets Start-Up Monthly",
    price: "59.99",
    period: "per month",
    perks: [
      <>
        <strong>10&nbsp;</strong>Seats at your agency
      </>,
      <>
        <strong>Unlimited&nbsp;</strong> Video Sends
      </>,
      <>
       Team <strong>&nbsp;and&nbsp;</strong> Individual Analytics
      </>,
      <>
        <strong>4&nbsp;</strong> Different Languages
      </>,
      <>
      <strong>Free&nbsp;</strong> Training Webinar
    </>,
    ],
  },
  // {
  //   id: "proc",
  //   name: "Million Dollar Tool Kit",
  //   price: "1,999.99",
  //   items: ["sby", "sbm", "tp", "tp1", "tp2", "tp3", "tp4", "proc1", "proc2", "proc3", "proc4", "proc5", "proc6", "proc7", "shuff"],

  //   period: "per year",
  //   perks: [
  //     <>
  //       <strong>5&nbsp;</strong>Seats at your agency
  //     </>,
  //     <>
  //       <strong>6&nbsp;</strong>Webinars
  //     </>,
  //     <>
  //       <strong>Proven&nbsp;</strong> Scripts
  //     </>,
  //     <>
  //       <strong>Team&nbsp;</strong> Progress Tracking
  //     </>,
  //     <>
  //     <strong>Medicare&nbsp;</strong> Powerpoint Presentation
  //     </>,
  //     <>
  //     <strong>Large Premium&nbsp;</strong> Life Sales Strategies
  //   </>,
  //   ],
  // },
  // {
  //   id: "procm",
  //   name: "Million Dollar Tool Kit Monthly",
  //   price: "199.99",
  //   items: ["sby", "sbm", "tp", "tp1", "tp2", "tp3", "tp4", "proc1", "proc2", "proc3", "proc4", "proc5", "proc6", "proc7", "shuff"],

  //   period: "per month",
  //   perks: [
  //     <>
  //       <strong>5&nbsp;</strong>Seats at your agency
  //     </>,
  //     <>
  //       <strong>6&nbsp;</strong>Webinars
  //     </>,
  //     <>
  //       <strong>Proven&nbsp;</strong> Scripts
  //     </>,
  //     <>
  //       <strong>Team&nbsp;</strong> Progress Tracking
  //     </>,
  //     <>
  //     <strong>Medicare&nbsp;</strong> Powerpoint Presentation
  //     </>,
  //     <>
  //     <strong>Large Premium&nbsp;</strong> Life Sales Strategies
  //   </>,
  //   ],
  // },
  {
    id: "tp",
    name: "Million Dollar Road Map",
    price: "3,999.99",
    items: ["sby", "sbm", "tp1", "tp2", "tp3", "tp4"],
    period: "per year",
    perks: [
      <>
        <strong>10&nbsp;</strong>Seats at your agency
      </>,
      <>
        <strong>$3,000&nbsp;</strong> Non-Compete
      </>,
      <>
       Staff Handbook
      </>,
      <>
        <strong>Million Dollar&nbsp;</strong> Manual
      </>,
      <>
        <strong>All Access&nbsp;</strong> to Sales Processes
      </>,
    ],
  },
  {
    id: "tpm",
    name: "Million Dollar Road Map Monthly",
    price: "399.99",
    items: ["sby", "sbm", "tp1", "tp2", "tp3", "tp4"],
    period: "per month",
    perks: [
      <>
        <strong>10&nbsp;</strong>Seats at your agency
      </>,
      <>
        <strong>$3,000&nbsp;</strong> Non-Compete
      </>,
      <>
       Staff Handbook
      </>,
      <>
        <strong>Million Dollar&nbsp;</strong> Manual
      </>,
      <>
      <strong>All Access&nbsp;</strong> to Sales Processes
    </>,
    ],
  },
  {
    id: "life",
    name: "Million-Dollar Membership",
    price: "14,999.99",
    period:"one time",
    perks: [
      <>
        <strong>One&nbsp;</strong> Payment
      </>,
      <>
        <strong>Annual&nbsp;</strong> Rocky Mountain Retreat
      </>,
      <>
        Access <strong>&nbsp;Everything</strong>
      </>,
      <>
       <strong>Additional&nbsp;</strong> weekly live trainings.
    </>,
      <>
      <strong>All&nbsp;</strong> additional future content 
      </>,
      <>
        <strong>Exclusive&nbsp;</strong> Offers and Events
      </>,
    ],
  },
];


const items = [
  {id: "tp1", name: "Million Dollar Manual", plans: ["tp", "proc"], price: "699.99", period: "per year"},
  {id: "tp2", name: "Comprehensive Staff Handbook", plans: ["tp", "proc"], price: "299.99", period: "per year"},
  {id: "tp3", name: "Paychecks Never Lie", plans: ["tp", "proc"], price: "24.99", period: "one time"},
  {id: "tp4", name: "Bigger Better Paychecks", plans: ["tp", "proc"], price: "24.99", period: "one time"},
  
  {id: "proc1", name: "Sales Process One: Medicare", plans: ["proc"], price: "199.99", period: "per year"},
  {id: "proc2", name: "Sales Process Two: Premium Life", plans: ["proc"], price: "199.99", period: "per year"},
  {id: "proc3", name: "Sales Process Three: Finding the Money", plans: ["proc"], price: "199.99", period: "per year"},
  {id: "proc4", name: "Sales Process Four: Protect & Particpate", plans: ["proc"], price: "199.99", period: "per year"},
  {id: "proc5", name: "Sales Process Five: Understanding Money", plans: ["proc"], price: "199.99", period: "per year"},
  {id: "proc6", name: "Sales Process Six: Liability Wall", plans: ["proc"], price: "199.99", period: "per year"},
  {id: "proc7", name: "Medicare Slide", plans: ["proc"], price: "199.99", period: "per year"},
  {id: "shuff", name: "Service Webinars", plans: ["proc"], price: "199.99", period: "per year"},

]

function arraysShareElement(arr1, arr2) {
  // Convert the first array to a Set for O(1) lookups
  const set1 = new Set(arr1);
  
  // Check if any element in the second array is in the Set
  for (const element of arr2) {
    if (set1.has(element)) {
      return true; // Return true if a common element is found
    }
  }
}

function PricingSection(props) {
  const router = useRouter()
  const auth = useAuth();
  const [selectedPlans, setSelectedPlans] = useState([])
  const [quantity, setQuantity] = useState(1)
  const [showMonth, setShowMonth] = useState(false)
  const [showMonthtp, setShowMonthtp] = useState(false)
  const [showMonthproc, setShowMonthproc] = useState(false)


  function calculateTotals(selectedPlans) {
    var one = 0
    var month = 0
    var year = 0
    var final = []
    selectedPlans.forEach(element => {
      if (plans.find(plan => plan.id === element)) {
         if (plans.find(plan =>  plan.id === element).period === "one time") {
          one += parseFloat(plans.find(plan => plan.id === element).price.replace(",", ""))
         } else if (plans.find(plan =>  plan.id === element).period === "per year") {
          year += parseFloat(plans.find(plan => plan.id === element).price.replace(",", ""))
         }else if (plans.find(plan =>  plan.id === element).period === "per month") {
          month += parseFloat(plans.find(plan => plan.id === element).price.replace(",", ""))
         }
      }
      if (items.find(item => item.id === element)) {
        if (items.find(plan =>  plan.id === element).period === "one time") {
          one += parseFloat(items.find(plan => plan.id === element).price.replace(",", ""))
         } else if (items.find(plan =>  plan.id === element).period === "per year") {
          year += parseFloat(items.find(plan => plan.id === element).price.replace(",", ""))
         }else if (items.find(plan =>  plan.id === element).period === "per month") {
          month += parseFloat(items.find(plan => plan.id === element).price.replace(",", ""))
         }
      }
    }
  );
  if (one>0) {
    final.push(`$${(one.toFixed(2)* Math.ceil(quantity/10)).toFixed(2)}`)
  }
  if (year>0) {
    final.push(`$${(year.toFixed(2)* Math.ceil(quantity/10)).toFixed(2)}/yr`)
  }
  if (month>0) {
    final.push(`$${(month.toFixed(2)* Math.ceil(quantity/10)).toFixed(2)}/mo`)
  }
return final
  }

  const appendPlan = (planId) => {

    setSelectedPlans(prevPlans => {
      let updatedPlans;
  
      if (prevPlans.includes(planId)) {
        updatedPlans = prevPlans.filter(element => element !== planId);
      } else {
        if (planId === "life") {
          updatedPlans = ["life"];
        } else {
          updatedPlans = [...prevPlans.filter(element => !singleIds.includes(element)), planId].filter(element => element!== "life");
          updatedPlans.forEach(each => {
            if (plans.find(plan => plan.id === planId).items?.includes(each)) {
            updatedPlans = updatedPlans.filter(element => element !== each)
            }
          })
        }
      }
      return updatedPlans;
    });
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (/^\d*$/.test(inputValue) && inputValue>0) { // Only allow integer values
      setQuantity(inputValue);
    }
  };

  return (<>
  {/* {auth.user?.legacyIsActive&&<FullBanner title="Attention" text = "It seems that your account has subscriptions that were purchased on our old website. Please note that if you wish to manage your existing subscriptions, you can do that by filling out a form in settings."/>} */}
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="container">
       {router.query.section ==="plans"&&<> 
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-4 items-stretch">
          {plans.map((plan, index) => (
            <>
              {(plan.id === "sby"&&!showMonth)&&
              <div className="flex flex-col items-center">
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={!showMonth}
                  onChange={() => setShowMonth(!showMonth)}
                  className="sr-only"
                />
                <div className="relative">
                  <div className={`block ${!showMonth?"bg-green-200 border border-green-700":"bg-gray-200"} w-14 h-8 rounded-full`}></div>
                  <div
                    className={`absolute left-2 top-1 w-6 h-6 rounded-full transition transform ${
                      !showMonth ? 'translate-x-5 bg-green-600' : 'bg-white'
                    }`}
                  ></div>
                </div>
                <span className="ml-3 text-sm">
                  <span className="font-medium text-gray-900">Annual billing</span>
                  <span className="text-gray-500"> (Save 16%)</span>
                </span>
              </label>
              <PlanCard subtitle= {plan.subtitle}owned ={(auth.user?.planIds?.includes(plan.id) || auth.user?.planIds?.includes("life")) && auth.user.planIsActive} handleClick={appendPlan} suppliedIds={selectedPlans} id = {plan.id} period={plan.visualPeriod?plan.visualPeriod:plan.period} key = {index} title = {plan.name} perks={plan.perks} price={plan.visualPrice?plan.visualPrice:plan.price}/>
              </div>
              }
              {(plan.id === "sbm"&&showMonth)&&
              <div className="flex flex-col items-center">
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={!showMonth}
                  onChange={() => setShowMonth(!showMonth)}
                  className="sr-only"
                />
                <div className="relative">
                  <div className={`block ${!showMonth?"bg-green-200 border border-green-700":"bg-gray-200"} w-14 h-8 rounded-full`}></div>
                  <div
                    className={`absolute left-1 top-1 w-6 h-6 rounded-full transition transform ${
                      !showMonth ? 'translate-x-5 bg-green-600' : 'bg-white'
                    }`}
                  ></div>
                </div>
                <span className="ml-3 text-sm">
                  <span className="font-medium text-gray-900">Annual billing</span>
                  <span className="text-gray-500"> (Save 16%)</span>
                </span>
              </label>
              <PlanCard subtitle= {plan.subtitle}owned ={(auth.user?.planIds?.includes(plan.id) || auth.user?.planIds?.includes("life")) && auth.user.planIsActive} handleClick={appendPlan} suppliedIds={selectedPlans} id = {plan.id} period={plan.visualPeriod?plan.visualPeriod:plan.period} key = {index} title = {plan.name} perks={plan.perks} price={plan.visualPrice?plan.visualPrice:plan.price}/>
              </div>}
              {(plan.id === "proc"&&!showMonthproc)&&
              <div className="flex flex-col items-center">
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={!showMonthproc}
                  onChange={() => setShowMonthproc(!showMonthproc)}
                  className="sr-only"
                />
                <div className="relative">
                  <div className={`block ${!showMonthproc?"bg-green-200 border border-green-700":"bg-gray-200"} w-14 h-8 rounded-full`}></div>
                  <div
                    className={`absolute left-2 top-1 w-6 h-6 rounded-full transition transform ${
                      !showMonthproc ? 'translate-x-5 bg-green-600' : 'bg-white'
                    }`}
                  ></div>
                </div>
                <span className="ml-3 text-sm">
                  <span className="font-medium text-gray-900">Annual billing</span>
                  <span className="text-gray-500"> (Save 16%)</span>
                </span>
              </label>
              <PlanCard subtitle= {plan.subtitle}owned ={(auth.user?.planIds?.includes(plan.id) || auth.user?.planIds?.includes("life")) && auth.user.planIsActive} handleClick={appendPlan} suppliedIds={selectedPlans} id = {plan.id} period={plan.visualPeriod?plan.visualPeriod:plan.period} key = {index} title = {plan.name} perks={plan.perks} price={plan.visualPrice?plan.visualPrice:plan.price}/>
              </div>
              }
              {(plan.id === "procm"&&showMonthproc)&&
              <div className="flex flex-col items-center">
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={!showMonth}
                  onChange={() => setShowMonthproc(!showMonthproc)}
                  className="sr-only"
                />
                <div className="relative">
                  <div className={`block ${!showMonthproc?"bg-green-200 border border-green-700":"bg-gray-200"} w-14 h-8 rounded-full`}></div>
                  <div
                    className={`absolute left-1 top-1 w-6 h-6 rounded-full transition transform ${
                      !showMonthproc ? 'translate-x-5 bg-green-600' : 'bg-white'
                    }`}
                  ></div>
                </div>
                <span className="ml-3 text-sm">
                  <span className="font-medium text-gray-900">Annual billing</span>
                  <span className="text-gray-500"> (Save 16%)</span>
                </span>
              </label>
              <PlanCard minimum={12} subtitle= {plan.subtitle}owned ={(auth.user?.planIds?.includes(plan.id) || auth.user?.planIds?.includes("life")) && auth.user.planIsActive} handleClick={appendPlan} suppliedIds={selectedPlans} id = {plan.id} period={plan.visualPeriod?plan.visualPeriod:plan.period} key = {index} title = {plan.name} perks={plan.perks} price={plan.visualPrice?plan.visualPrice:plan.price}/>
              </div>}
              {(plan.id === "tp"&&!showMonthtp)&&
              <div className="flex flex-col items-center">
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={!showMonthtp}
                  onChange={() => setShowMonthtp(!showMonthtp)}
                  className="sr-only"
                />
                <div className="relative">
                  <div className={`block ${!showMonthtp?"bg-green-200 border border-green-700":"bg-gray-200"} w-14 h-8 rounded-full`}></div>
                  <div
                    className={`absolute left-2 top-1 w-6 h-6 rounded-full transition transform ${
                      !showMonthtp ? 'translate-x-5 bg-green-600' : 'bg-white'
                    }`}
                  ></div>
                </div>
                <span className="ml-3 text-sm">
                  <span className="font-medium text-gray-900">Annual billing</span>
                  <span className="text-gray-500"> (Save 16%)</span>
                </span>
              </label>
              <PlanCard subtitle= {plan.subtitle}owned ={(auth.user?.planIds?.includes(plan.id) || auth.user?.planIds?.includes("life")) && auth.user.planIsActive} handleClick={appendPlan} suppliedIds={selectedPlans} id = {plan.id} period={plan.visualPeriod?plan.visualPeriod:plan.period} key = {index} title = {plan.name} perks={plan.perks} price={plan.visualPrice?plan.visualPrice:plan.price}/>
              </div>
              }
              {(plan.id === "tpm"&&showMonthtp)&&
              <div className="flex flex-col items-center">
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={!showMonthtp}
                  onChange={() => setShowMonthtp(!showMonthtp)}
                  className="sr-only"
                />
                <div className="relative">
                  <div className={`block ${!showMonthtp?"bg-green-200 border border-green-700":"bg-gray-200"} w-14 h-8 rounded-full`}></div>
                  <div
                    className={`absolute left-1 top-1 w-6 h-6 rounded-full transition transform ${
                      !showMonthtp ? 'translate-x-5 bg-green-600' : 'bg-white'
                    }`}
                  ></div>
                </div>
                <span className="ml-3 text-sm">
                  <span className="font-medium text-gray-900">Annual billing</span>
                  <span className="text-gray-500"> (Save 16%)</span>
                </span>
              </label>
              <PlanCard minimum={12} subtitle= {plan.subtitle}owned ={(auth.user?.planIds?.includes(plan.id) || auth.user?.planIds?.includes("life")) && auth.user.planIsActive} handleClick={appendPlan} suppliedIds={selectedPlans} id = {plan.id} period={plan.visualPeriod?plan.visualPeriod:plan.period} key = {index} title = {plan.name} perks={plan.perks} price={plan.visualPrice?plan.visualPrice:plan.price}/>
              </div>}
              {(plan.id === "life")&&
              <PlanCard subtitle= {plan.subtitle}owned ={(auth.user?.planIds?.includes(plan.id) || auth.user?.planIds?.includes("life")) && auth.user.planIsActive} handleClick={appendPlan} suppliedIds={selectedPlans} id = {plan.id} period={plan.visualPeriod?plan.visualPeriod:plan.period} key = {index} title = {plan.name} perks={plan.perks} price={plan.visualPrice?plan.visualPrice:plan.price}/>
              }
            </>
          ))}
        </div>
        </>}

        {(selectedPlans.length>0)?
        <div className="flex flex-row justify-between border-t-4 border-green-700 m-4 p-8">
        <div>
          <label htmlFor="seats" className="block text-sm font-medium leading-6 text-gray-900">
            Number of Seats
          </label>
          <div className="relative mt-2 rounded-md">
            <input
              id="seats"
              name="seats"
              type="text"
              value={quantity}
              placeholder="5"
              onChange={handleChange}
              className="block w-32 rounded-md border-0 py-4 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 text-2xl font-thin sm:leading-6"
            />
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 flex flex-row">
            <div className="flex flex-col">
              <button onClick={()=>setQuantity(quantity+1)} className="hover:bg-gray-100 rounded-full">
                <ChevronUpIcon className="h-6"/>
                </button>
              <button onClick={() => setQuantity(quantity>1?quantity-1:1)} className="hover:bg-gray-100 rounded-full">
                <ChevronDownIcon className="h-6"/>
              </button>
            </div>
            <div className="group">
              <QuestionMarkCircleIcon aria-hidden="true" className="h-8 w-8 text-green-700 relative" />
              <div className="z-40 absolute left-20 top-0 w-64 p-2 bg-white text-gray-700 border border-green-700 rounded-md text-sm hidden group-hover:block">
                Each plan or product comes with a default of 10 seats. This means you and nine other members within your team will have access to the product.
                Please enter how many seats you need, and we will calculate the quantity needed for you.
              </div>
            </div>
          </div>
          </div>
        </div>
        <Link to = {(arraysShareElement(auth.user.planIds, ["sby", "sbm","sbm1","tp", "proc","tpl","tpm","procm"])&&arraysShareElement(["sby", "sbm1", "sbm","tp", "proc","procm","tpl","tpm"], selectedPlans))?"/settings/billing":`/purchase/${Math.ceil(quantity/10)}-${selectedPlans.join("-")}`}>
        <button className="w-full text-xl bg-green-700 rounded-lg text-white p-3 my-4">Purchase Selected Plans</button>
        </Link>

        </div>

        :
        <button disabled={true} className="w-full text-xl bg-gray-100 rounded-lg text-green-700 p-3 my-4">Select a Plan to Purchase</button>}

      </div>
      {selectedPlans.length>0&&
      <>
      <div className="fixed top-20 right-5 w-72 p-4 bg-white border border-green-700 rounded-lg">
      <ul className="list-none p-0 m-0">
        {selectedPlans.map((item, index) => (
          <li
            key={index}
            className="flex flex-row justify-between py-2 border-b last:border-none border-gray-200 text-xs font-bold text-green-700"
          >
            {Math.ceil(quantity/10)+" x "}
            {plans.find(element => element.id === item)?.name || items.find(element => element.id === item)?.name}
            <p className="text-gray-400">${plans.find(element => element.id === item)?.price || items.find(element => element.id === item)?.price}{((plans.find(element => element.id === item)?.period=== "per year")|| (items.find(element => element.id === item)?.period) === "per year")?"/yr":((plans.find(element => element.id === item)?.period === "per month")|| (items.find(element => element.id === item)?.period) === "per month")?"/mo":""}</p>
          </li>
        ))}
        <li
            className="flex flex-row justify-between py-2 border-t border-green-700 text-xs font-bold text-green-700"
          >
            {"Subtotal: "}

            <p className="text-gray-400">{ calculateTotals(selectedPlans).join(" + ")}</p>
          </li>
      </ul>
    </div>
      </>}
    </Section>
</>
  );
}

export default PricingSection;
