import React from 'react';
import { Link } from '../../util/router';

const ThreeCardRow = (props) => {
  return (

    <>
    {(React.Children.count(props.children) > 0)?
      <div className="flex flex-col md:flex-row lg:flex-row justify-center items-center md:space-x-4 mt-6">
      <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4`}>
        {React.Children.map(props.children, (child) => {
          return <>{child}</>;
        })}
      </div>
    </div>
    :
      <>
<div className="flex flex-col md:flex-row lg:flex-row justify-center items-center md:space-x-4 mt-6">
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {/* Card 1 */}
          <Link to = "/tools/sp">
          <div className="rounded-lg p-4 max-w-sm w-full bg-green-100">
            <div className="flex flex-col items-center">
              <h3 className="text-lg font-semibold">Webinars</h3>
              <p className="text-gray-600 text-sm mt-1">Access a wealth of information and resources to enhance your methods and sales.</p>
            </div>
          </div>
          </Link>

        {/* <Link to = "/blog/all">
        <div className="rounded-lg p-4 max-w-sm w-full bg-green-100">
          <div className="flex flex-col items-center">
            <h3 className="text-lg font-semibold">Blog Posts</h3>
            <p className="text-gray-600 text-sm mt-1">Read about powerful solutions to increase performance, drive sales, and win.</p>
          </div>
        </div>
        </Link> */}

        {/* Card 3 */}
        <Link to = "/dashboard/analytics">
        <div className="rounded-lg p-4 max-w-sm w-full bg-green-100">
          <div className="flex flex-col items-center">
            <h3 className="text-lg font-semibold">Analytics</h3>
            <p className="text-gray-600 text-sm mt-1">Gain insights into your own and teams' performance with comprehensive analytics.</p>
          </div>
        </div>
        </Link>
        </div>
        </div>
            </>
    }
    </>
  );
};


export default ThreeCardRow;
