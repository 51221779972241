export default function TpPackages() {
    return (
        <div className="bg-white py-12 sm:py-12">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                    <p className="text-base/7 font-semibold text-green-600">Agency Training Package</p>
                    <h1 className="mt-2 text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                        Pricing and Features
                    </h1>
                    <div className="mt-10 grid max-w-xl grid-cols-1 gap-8 text-base/7 text-gray-700 lg:max-w-none lg:grid-cols-2">
                        <div className="border border-green-700 rounded-lg p-6 shadow-lg">
                            <h2 className="text-2xl font-bold tracking-tight text-green-800">Pricing</h2>
                            <p className="mt-4">
                                <strong>Monthly Plan:</strong> $399.99 per month<br />
                                <strong>Annual Plan:</strong> $3999.99 per year
                            </p>
                        </div>

                        <div className="border border-green-700 rounded-lg p-6 shadow-lg">
                            <h2 className="text-2xl font-bold tracking-tight text-green-800">Core Offer and Features</h2>
                            <ul className="mt-4 space-y-4 text-start">
                                <li><strong>Stairs And Buckets:</strong> Core foundational process included.</li>
                                <li><strong>All Seven Sales Processes:</strong> Comprehensive strategies for diverse client needs.</li>
                                <li><strong>Agency Training Manual:</strong> Gold-standard resource for team development.</li>
                                <li><strong>Staff Handbook:</strong> Policies and legal protection for your agency.</li>
                                <li><strong>Non-Compete Clause Template:</strong> Protects business interests.</li>
                                <li><strong>Two Books:</strong> Proven strategies for success.</li>
                                <li><strong>Bonus Process:</strong> Service to Sales framework for converting service opportunities into sales.</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none my-8">
                    <h1 className="mt-2 text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                        Detailed Features
                    </h1>
                    <div className="mt-10 grid max-w-xl grid-cols-1 gap-8 text-base/7 text-gray-700 lg:max-w-none lg:grid-cols-2">
                        <div className="border border-green-700 rounded-lg p-6 shadow-lg">
                            <h2 className="text-2xl font-bold tracking-tight text-green-800">The Seven Sales Processes</h2>
                            <p>These processes align with clients’ unique needs and risks at every stage of life:</p>
                            <ul className="mt-4 space-y-4 text-start">
                                <li><strong>Stairs and Buckets:</strong> Foundational process for success.</li>
                                <li><strong>Medicare 101:</strong> Simplifies Medicare education for clients, increasing understanding and confidence.</li>
                                <li><strong>Large Premium Life with FCB Rider:</strong> A targeted strategy to secure large premium life insurance clients.</li>
                                <li><strong>Finding the Money:</strong> Helps identify clients’ budget potential and untapped financial resources.</li>
                                <li><strong>Protect and Participate:</strong> Balances market participation and protection for client investments.</li>
                                <li><strong>Understanding Money:</strong> Educates clients on fundamental money principles for informed decision-making.</li>
                                <li><strong>Liability Wall:</strong> Shields clients from financial risks such as liability and home damage.</li>
                                <li><strong>Bonus Process: Service to Sales:</strong> Converts service calls into revenue opportunities.</li>
                            </ul>
                        </div>

                        <div className="border border-green-700 rounded-lg p-6 shadow-lg">
                            <h2 className="text-2xl font-bold tracking-tight text-green-800">Agency Training Manual</h2>
                            <p>This manual serves as the gold standard for training teams in insurance sales:</p>
                            <ul className="mt-4 space-y-4 text-start">
                                <li><strong>Developing Your Team’s Why:</strong> Builds motivation and purpose.</li>
                                <li><strong>Telephone and Email Skills:</strong> Mastering communication essentials.</li>
                                <li><strong>Lapse Can Strategies:</strong> Techniques to recover and retain lapsed policies.</li>
                                <li><strong>Auto and Home Strategies:</strong> Cross-selling for maximum value.</li>
                                <li><strong>Liability, Life, and Health Processes:</strong> Essential frameworks for key insurance products.</li>
                                <li><strong>FORM Strategy:</strong> A customer-centric conversational guide focused on Family, Occupation, Recreation, and Money.</li>
                                <li><strong>Characteristics of a Successful Team:</strong> Identifies skills needed for success in insurance sales.</li>
                                <li><strong>Partner Resources:</strong> Tools and tips for strengthening networks and partnerships.</li>
                            </ul>
                        </div>
                    </div>

                    <div className="mt-10 grid max-w-xl grid-cols-1 gap-8 text-base/7 text-gray-700 lg:max-w-none lg:grid-cols-2">
                        <div className="border border-green-700 rounded-lg p-6 shadow-lg">
                            <h2 className="text-2xl font-bold tracking-tight text-green-800">Staff Handbook</h2>
                            <p>A comprehensive guide for team onboarding and management:</p>
                            <ul className="mt-4 space-y-4 text-start">
                                <li><strong>Policies and Procedures:</strong> Clear job descriptions, daily operating schedules, and financial management policies.</li>
                                <li><strong>Agency Security Protocols:</strong> Ensures compliance and security.</li>
                                <li><strong>Harassment Policies:</strong> Includes hiring and termination procedures.</li>
                                <li><strong>Non-Compete Agreement:</strong> A $3,000 legal document crafted by an attorney to protect your client base.</li>
                            </ul>
                        </div>

                        <div className="border border-green-700 rounded-lg p-6 shadow-lg">
                            <h2 className="text-2xl font-bold tracking-tight text-green-800">Books</h2>
                            <ul className="mt-4 space-y-4 text-start">
                                <li><strong>Paychecks Never Lie:</strong> Focuses on improving business results, coaching strategies, and actionable practices.</li>
                                <li><strong>BIGGER PAYCHECK$, BETTER PAYCHECK$</strong> Emphasizes small, consistent improvements for big results over time.</li>
                            </ul>

                            <h2 className="mt-8 text-2xl font-bold tracking-tight text-green-800">Bonus Process: Service to Sales</h2>
                            <p>A webinar training series designed to help agents turn service interactions into sales:</p>
                            <ul className="mt-4 space-y-4 text-start">
                                <li><strong>Sell Off Every Transaction:</strong> Strategically turns routine interactions into sales opportunities.</li>
                                <li><strong>Transform Conversations:</strong> Shifts service calls into meaningful discussions about financial well-being.</li>
                                <li><strong>Maximize Opportunities:</strong> Ensures every client touchpoint contributes to sales goals without being pushy.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
