import React, { useEffect, useState } from "react";
import { useUserOnce } from "../../util/db";
import { getFriendlyPlanId, getStripePriceId } from "../../util/prices";
export default function PaymentWrapperNode({uid, onValueChange, requiredPlans, tags}) {

    function haveCommonElements(arr1, arr2) {
      if (arr1 && arr2) {
        const set1 = new Set(arr1);
        for (const element of arr2) {
          if (set1.has(element)) {
            return true;
          }
        }
        return false
      } else {
        return false;
      }
      }


   const {
    data: owner,
    status: ownerStatus,
    error: ownerError
   } = useUserOnce(uid)


   useEffect(() => {
    var currentTime = Date.now(); // Current server timestamp

    if (ownerStatus === "success" && owner && (owner.stripePriceIds || owner.legacyPlans)) {
        var temp = owner.stripePriceIds?.map(each => getFriendlyPlanId(each))



        if ((["active", "trialing"].includes(owner.stripeSubscriptionStatus)
            &&(
                (owner.stripePriceIds?.includes(getStripePriceId("life")) || 
                haveCommonElements(temp, requiredPlans)) ||
                haveCommonElements(tags, owner.tags)

            )) || 
            ((
              ((owner.legacyInterval==="month" && (currentTime/1000 - owner.legacyRecentPayment) < 31*60*24*60)||
              (owner.legacyInterval==="year" && (currentTime/1000 - owner.legacyRecentPayment) < 365*60*24*60)) ||
              ((owner.legacyInterval==="month" && (currentTime/1000 - owner.legacyRecentPayment.seconds) < 31*60*24*60)||
              (owner.legacyInterval==="year" && (currentTime/1000 - owner.legacyRecentPayment.seconds) < 365*60*24*60))
            )&&(
              haveCommonElements(owner.legacyPlans, requiredPlans) ||
              owner.legacyPlans?.includes("life") || 
              haveCommonElements(tags, owner.tags))

            )
      ){
            onValueChange(true)
        } else {
            onValueChange(false)
        }
    }
   }, [owner, ownerStatus])
 return (
    <>
    </>
 )
}

