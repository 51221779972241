import React, { useState } from "react";
import CollapsibleList from "./CollapsibleList";
import SupportForm from "./SupportForm";

const faqs = [
    { question: 'How do I find the tools I purchased?', answer: "To navigate to the Tools section, click on the green sidebar on the left side of the screen. You'll see the Tools option, which is split into three sections: 'Stairs and Buckets,' 'Webinars,' and 'Trainings.' Select the section you need to access." },
    { question: 'How do I switch between the application and the front-end site?', answer: "Click on the account dropdown in the top right corner of the application. You will see an option to 'Exit application.' Click this to go to the front-end site. To return to the application, follow the same steps and click 'Enter application.'" },
    { question: 'How can I update my billing information?', answer: "To update your billing information, go to the Settings page by clicking on the green sidebar. Select 'Settings,' then navigate to the 'Subscriptions' tab. Click on 'Manage subscriptions' to update your billing information." },
    { question: 'How do I change my notification settings?', answer: 'You can change your notification settings by navigating to the Settings page via the green sidebar. In the Settings, find the notification settings option and adjust them according to your preferences.' },
    { question: 'Can I delete a team I no longer need?', answer: 'Yes, you can delete a team by navigating to the Teams section via the green sidebar. Select the team you wish to delete and click the delete option. Please note that this action is irreversible.' },
    { question: "I can't access the Calendar on my mobile device. What should I do?", answer: 'The Calendar feature is only available on desktop devices. Please try accessing it from a desktop or laptop computer. If you still experience issues, contact our support team via the Help Center.' },

    // Add more questions and answers as needed
  ];

function PublicHelpSection(props) {
  const [visible, setVisible] = useState(false)

  return (
    <>

      <div className="container">
          <>
            <div className="flex flex-row justify-between items-center mb-6">

        </div>
        <div className="text-3xl font-semibold text-center">Frequently Asked Questions</div>
        <div className="text-lg text-center text-gray-500">Explore frequently asked questions about Insurance Agency training, including the services we provide, and how to contact us.</div>
        <CollapsibleList faqs={faqs}/>
        <div>
          {visible&&<div className="border border-green-700 mb-4 p-2 rounded-lg"><SupportForm/></div>}
          <button onClick={() => setVisible(!visible)} className="w-full bg-green-700 rounded-lg p-2 text-lg text-gray-200 font-bold">
            {visible?"Close Support Ticket":"File a support ticket"}
          </button>
          <p className="text-center m-2">Alternatively you can email us directly at <strong>support@insuranceagencytraining.org</strong></p>
          </div>
        </>

      </div>
    </>

  );
}

export default PublicHelpSection;
