import React from "react";
import Meta from "./../components/Meta";
import { requireAuth } from "./../util/auth";
import CustomSettingsSection from "../components/Custom/CustomSettingsSection";

function SettingsPage(props) {

  return (
    <>
      <Meta title="Settings" />
      <CustomSettingsSection/>
    </>
  );
}

export default requireAuth(SettingsPage);
