import React, { useState } from "react";
import {PlusCircleIcon} from "@heroicons/react/24/solid";
import { createGPTThread } from '../../util/useGPT';
import { createSupportThread } from "../../util/db";

function ChatListView(props) {

    const [selected, setSelected] = useState(props.selected)
    const defaultAvatar = 'https://via.placeholder.com/150?text=Avatar';

      async function createThread() {
        try {
          const res = await createGPTThread();
          if (res) {
            createSupportThread({owner: props.uid, threadId: res.data.thread_id, contact: "Virtual Support"})
          }
        } catch (error) {
          console.error('Error fetching chat completion:', error);
        }
      }




    return (
        <nav className="flex flex-1 flex-col p-2 border rounded-lg" aria-label="Sidebar">
            <div className="flex flex-row justify-between border-b border-gray-300 pb-2">
                <p className="font-semibold">All Chats</p>
            </div>
            
        <ul className="divide-y divide-solid">
        {props.chats?.map((chat, index) => (
        <div
          key={index}
          onClick={() =>{
            setSelected(index)
            props.handleSelection(index)}}
          className={`flex items-center p-4 ${(selected === index)?"bg-green-100 border-l-4 border-green-600":"hover:bg-gray-100"} border-gray-300 cursor-pointer `}
        >
          <img
            src={defaultAvatar}
            alt={chat.name}
            className="h-14 w-14 rounded-full object-cover mr-3"
          />
          <div className="flex flex-1 min-w-0">
            <div className="flex flex-col justify-between">
              <span className="text-sm font-medium text-gray-900 truncate">{chat.contact}</span>
              <span className="text-xs text-gray-500">{`Case: ${chat.id[0]}${chat.id[1]}${chat.id[2]}${chat.id[3]}${chat.id[4]}`}</span>
            </div>
          </div>
        </div>
      ))}
        </ul>
        <button
            type="button"
            onClick={() => createThread()}
            className="relative flex flex-col items-center block w-full rounded-lg border-2 border-dashed border-green-700 p-4 text-center hover:border-green-500 focus:outline-none text-green-700 hover:text-green-500 hover:bg-green-50"
        >
            <PlusCircleIcon className="h-12 justify-center "/>
            <span className="mt-2 block text-sm font-semibold text-gray-900">Create a New Virtual Support Ticket</span>
        </button>
        </nav>
    )
}

export default ChatListView