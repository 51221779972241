import React, {useRef, useEffect} from 'react';
import  Player  from '@vimeo/player';
import { updateUser } from '../../util/db';


const VideoCard = ({ embedCode, title, description, uid, postId, prev }) => {
  const iframeRef = useRef(null);

  useEffect(() => {
    const player = new Player(iframeRef.current);

    player.on('ended', () => {
      if (postId === "sb" ) {
        updateUser(uid, {
          IpSbCount: prev?prev+1:1
        })
      } else {
      if (prev && prev.includes(postId)) {
        console.log("already watched")
      }
      console.log('Video has ended');
      if (postId && uid &&prev && !prev.includes(postId)) {
        updateUser(uid, {
          finishedContent: [postId, ...prev]
        })
      } else if (postId&& uid) {
        updateUser(uid, {
          finishedContent: [postId]
        })
      }
    }
    });

    return () => {
      player.off('ended');
    };
  }, []);
  return (
    <div className="border border-gray-300 rounded-lg p-4 w-full mx-auto">
      <div className="mb-4">
        <iframe
          ref={iframeRef}
          src={"https://player.vimeo.com/video/" + embedCode + "?&title=0&byline=0&portrait=0&badge=0&dnt=1"}
          width="100%"
          height="400"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          title="Vimeo Video"
        ></iframe>
      </div>
      <h2 className="text-xl font-semibold mb-2">{title}</h2>
      <p className="text-gray-700">{description}</p>
    </div>
  );
};

export default VideoCard;
