import React from "react";
import Meta from "./../components/Meta";
import PricingSection from "./../components/PricingSection";
function PricingPage(props) {
  return (
    <>
      <Meta title="Pricing" />
      <PricingSection
        title="Choose the best plan"
        subtitle="Three tiers at three different price points. Each purchase includes 10 seats for your agency. If you need more, you must purchase additional subscriptions."
        strapline="Pricing"
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
    </>
  );
}

export default PricingPage;
