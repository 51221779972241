import React from "react";

import Meta from "../../../components/Meta";
import TempLandingPageSection from "../../../components/Custom/TempLandingPageSection";

function TempLandingPage(props) {

  return (
    <>
        <Meta title="Promotional Landing" />
        
        <TempLandingPageSection/>

    </>
  );
}

export default TempLandingPage;
