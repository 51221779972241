import React, { useState, useEffect } from 'react';

const CookieConsentBanner = () => {
  const [accepted, setAccepted] = useState(false);

  useEffect(() => {
    const hasAccepted = localStorage.getItem('cookies_accepted') === 'true';
    setAccepted(hasAccepted);
  }, []);

  const handleAccept = () => {
    setAccepted(true);
    localStorage.setItem('cookies_accepted', 'true');
  };

  if (accepted) return <></>;

  return (
    <div className="fixed bottom-0 inset-x-0 bg-white border-t border-green-700 text-black text-sm p-4 flex items-center justify-between z-50">
      <span>
        We use cookies to enhance your experience. By clicking &quot;Accept&quot; you agree to our use of cookies as outlined in our <a className='font-bold text-green-700' href='/legal/privacy-policy'>Privacy Policy</a>.
      </span>
      <button
        onClick={handleAccept}
        className="ml-4 bg-green-700 text-white font-bold py-2 px-4 rounded"
      >
        Accept
      </button>
    </div>
  );
};

export default CookieConsentBanner;
