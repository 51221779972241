import React from "react";
import { useAllBlogPosts, useBlogPostOnce } from "../../util/db";
import { useRouter } from "../../util/router";
import ResourceCard from "./ResourceCard";
import { Link } from "../../util/router";
import { all } from "axios";
import MarkdownRenderer from "./MarkDownRenderer";
function AllBlogPostsSection() {
    const router = useRouter()

    const {
        data: blogData,
        status: blogDataStatus,
        error: blogDataError
    } = useAllBlogPosts()

    const {
        data: currentData,
        status: currentStatus,
        error: currentError
    } = useBlogPostOnce(router.query.index)

  return (
    <>
    {(blogData && blogData.length>0 && blogDataStatus==="success") &&(
        <>
        {(router.query.index === "all")&&(
        <div className="container mx-auto py-8">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {blogData.map((card, index) => (
                <div  key = {index} className="border border-2 rounded-3xl p-4">
                <ResourceCard photo={card.coverPhoto} title={card.title} description={card.description} href={`/blog/${card.id}`}/>
                </div>
            ))}
            </div>
        </div>
        )}
        
        {(parseInt(router.query.index) !== all && router.query.index && currentStatus === "success" && currentData)&&(
            <div className="items-center text-center">
            <div className="max-w-4xl mx-auto bg-white rounded-lg border border-gray-300 overflow-scroll text-start">
            <div className="px-6 pt-6">
                <h2 className="text-green-700 text-4xl font-semibold">{currentData.title}</h2>
            </div>
            <div className="p-6">
            <MarkdownRenderer link={currentData.embed}/>
            </div>
            </div>
            <Link to ={"/blog/all"}>
            <button className="w-1/2 bg-green-700 p-4 rounded-lg m-6 text-white text-xl">Back to All Blog Posts</button>
            </Link>
            </div>
        )}
        </>
        )}
    </>
  );
}

export default AllBlogPostsSection;
