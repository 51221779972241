import React from "react";
import Meta from "./../components/Meta";
import { requireAuth } from "./../util/auth";
import CustomDashboard from "../components/Custom/CustomDashboard";

function DashboardPage(props) {
  return (
    <>
      <Meta title="Dashboard" />
      <CustomDashboard
      title="Dashboard"
      subtitle=""
      strapline=""
      size="md"
      bgColor="bg-white"/>
    </>
  );
}

export default requireAuth(DashboardPage);
