import React, { useState } from "react";
import { useAuth } from "../../util/auth";
import { apiRequest } from "../../util/util";

export default function PasswordUpdateButton(props) {
    const auth = useAuth();
    const [showPopup, setShowPopup] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const updatePassword = async (uid, password) => {
        try {
            setIsLoading(true);
            const res = await apiRequest("admin", "POST", {
                uid: uid,
                password: password,
                authenticatedUserId: auth.user.uid
            });
            console.log("Response after updating password: ", res);
            setShowPopup(false);
        } catch (error) {
            console.error("Error updating password: ", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleUpdate = () => {
        if (newPassword.trim()) {
            updatePassword(props.uid, newPassword);
        } else {
            alert("Password cannot be empty");
        }
    };

    return (
        <div className="relative inline-block">
            <button 
                onClick={() => setShowPopup(true)} 
                className="border border-green-700 rounded-full px-2"
            >
                Password
            </button>

            {showPopup && (
                <div className="absolute top-10 left-0 bg-white shadow-lg rounded p-4 w-64 z-50">
                    <h3 className="text-sm font-semibold mb-2">Enter New Password</h3>
                    <input
                        type="text"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        className="w-full border border-gray-300 rounded p-2 mb-4"
                        placeholder="New Password"
                    />
                    <div className="flex justify-end gap-2">
                        <button
                            onClick={() => setShowPopup(false)}
                            className="text-sm text-gray-500 hover:text-gray-700"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={handleUpdate}
                            disabled={isLoading}
                            className="text-sm bg-green-700 text-white rounded px-3 py-1 hover:bg-green-800"
                        >
                            {isLoading ? "Updating..." : "Update"}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}
