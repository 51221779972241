import React, { useState, useEffect } from "react";
import { Link, useRouter } from "../../util/router";
import { useAuth } from "../../util/auth";
import SideNav from "./SideNav";
import LargeCard from "./LargeCard";
import EditInformationCard from "./EditInformationCard";
import EntityCard from "./EntityCard";
import { useUser, useNotifications } from "../../util/db";
import NotificationsSection from "./NotificationsSection";
import SettingsBilling from "../SettingsBilling";
import SettingsPassword from "../SettingsPassword";
import FormAlert from "../FormAlert";
import MarkdownRenderer from "./MarkDownRenderer";
import { getPlanName } from "../../util/prices";


const navigation = [
    { name: 'Account Details', href: 'account', current: false },
    { name: 'Notifications', href: 'notifications', current: false },
    { name: 'Subscriptions', href: 'subscriptions', current: false },
    { name: 'Terms and Conditions', href: 'tac', current: false },
    { name: 'Privacy Policy', href: 'privacy', current: false },
    // { name: 'About', href: 'about', current: false },

  ]
  
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  

function CustomSettingsSection(props) {

  const priv = require("../../assets/privacy_policy.md")
  const tos = require("../../assets/terms_of_service.md")

  const auth = useAuth();
  const router = useRouter()
  const [billing, setBilling] = useState(false)
  const [priv_pol_md, setPriv_pol_md] = useState("")
  const [tos_md, setTos_md] = useState("")
  const [formAlert, setFormAlert] = useState(null);




  // State to control whether we show a re-authentication flow
  // Required by some security sensitive actions, such as changing password.
  const [reauthState, setReauthState] = useState({
    show: false,
  });

  const [showPasswordModal, setShowPasswordModal] = useState(false)

  const {
    data: user,
    error: userError,
    status: userStatus
  } = useUser(auth.user.uid)

  const {
    data: notifications,
    error: notificationsError,
    status: notificationsStatus
  } = useNotifications(auth.user.uid)


  const handleStatus = ({ type, message, callback }) => {
    if (type === "requires-recent-login") {
      // First clear any existing message
      setFormAlert(null);
      // Then update state to show re-authentication modal
      setReauthState({
        show: true,
        // Failed action to try again after reauth
        callback: callback,
      });
    } else {
      // Display message to user (type is success or error)
      setFormAlert({
        type: type,
        message: message,
      });
      if (type === "success") {
        setTimeout(() => setShowPasswordModal(false), 3000);
      }
    }
  };

  useEffect(() => {
    fetch(priv)
      .then((res) => res.text())
      .then((text) => setPriv_pol_md(text));

    fetch(tos)
      .then((res) => res.text())
      .then((text) => setTos_md(text));
  }, []);

  return (
    <>

      <div className="container">
      <div className="flex w-full flex-col md:flex-row">
    <div className="w-full md:w-1/4 px-4">
      <nav className="flex flex-col md:flex-1 md:flex-col" aria-label="Sidebar">
            <ul className="-mx-2 space-y-1">
            {navigation.map((item) => (
                <li key={item.name}>
                    <Link
                        to={`/settings/${item.href}`}
                        className={
                        classNames(router.query.section === item.href?"text-green-700 border-r-4 border-solid border-green-600 hover:text-green-700 hover:bg-gray-50":"text-gray-400 hover:text-green-700 hover:bg-gray-50",'group flex gap-x-3 p-2 pl-3 text-md leading-6')
                        }
                    >
                        {item.name}
                    </Link>
                </li>
            ))}
            </ul>
        </nav>
    </div>
        <div className="flex-grow px-4">
        {(router.query.section === "account")&&(
          <>
        <div className="flex w-full">

            <LargeCard titleDescriptions = {[{title: "Account Details", description: "Manage your account settings and preferences easily."}]}>
              {(userStatus==="success" && user)&&(
              <>
              <EntityCard imageUrl = {user.profilePhoto} name = {user.name} role = {user.role} company = {user.company} edit = {true} uid = {auth.user.uid}/>
              <EditInformationCard collection = {"users"} id={auth.user.uid} title = "Information" informationArray = {[
                {name: "Name", value: user.name? user.name: "NA"},
                {name: "Company", value: user.company? user.company: "NA"},
                {name: "Email", value: auth.user.email? auth.user.email: "NA"},
                {name: "Phone Number", value: auth.user.phone? auth.user.phone: "NA"},
                {name: "Role", value: user.role?user.role:"NA"},
                ]}> 
              </EditInformationCard>
              {(showPasswordModal)?<>
                {formAlert && (
                  <div className="mb-4">
                    <FormAlert type={formAlert.type} message={formAlert.message} />
                  </div>
                )}
              <SettingsPassword onStatus = {handleStatus}/>
              </>:<button 
              className="flex flex-row px-4 h-min py-2 mt-2 bg-gray-white text-green-700 border border-solid border-green-600 rounded-md justify-center"
               onClick={() => setShowPasswordModal(true)}>Change Password</button>}

              </>

              )}

            </LargeCard>
        </div>
        </>
        )}
        {(router.query.section === "notifications")&&(
          <>
        <div className="flex w-full">
          <LargeCard titleDescriptions = {[{title: "Notifications", description: "Tailor notifications to suit your workflow and stay informed. Make sure to save your changes before you are done!"}]}>
            {(notificationsStatus==="success"&&notifications && notifications.length===1)&&<NotificationsSection data = {notifications[0]}/>}
          </LargeCard>
        </div>
        </>
        )}
        {(router.query.section === "subscriptions")&&(
          <>
        <div className="flex w-full">
        <LargeCard titleDescriptions = {[{title: "Subscriptions", description: "Keep track of your subscription status and manage plans effortlessly. Please note, you can only be using one subscription at a time."}]}>
            
            <h3 className="text-lg font-semibold">Current Subscriptions</h3>
            <p className="text-gray-400 text-sm mt-1">{auth.user.planIsActive?"Woohoo! You are subscribed to a plan!":"Hmmm, looks like you need a plan?"}</p>
            {(auth.user.planIsActive || auth.user.legacyIsActive)&&(
              <div className="flex flex-row items-center space-x-6">
                <div>
            <p className="text-gray-400 text-sm mt-1">{"Current Subscription(s): "}</p>
            <div className="flex flex-col">
              {auth.user.planIds?.map((each, i ) => 
              <p key = {i} className="text-green-700 text-md mt-1">
              {getPlanName(each)}
              </p>
            )}
            {auth.user.legacyPlans?.map((each, i) => 
              <p key = {i} className="text-green-700 text-md mt-1">
              {getPlanName(each)} Legacy Plan
              </p>
            )}
            </div>
            </div>
            <button onClick={() => setBilling(true)} className="bg-green-700 px-4 py-2 text-white rounded-lg">Manage Subscription</button>
            {(billing)&&<SettingsBilling onStatus={handleStatus}/>}
            </div>
            )}

            <div className='w-full border-gray-200 border-t my-6'/>   
            <h3 className="text-lg font-semibold">Wanna Upgrade?</h3>
            <Link to ="/pricing/plans">
            <button className="bg-green-700 px-4 py-2 text-white rounded-lg w-full">
            Visit our Shop
            </button>
            </Link>
        </LargeCard>
        </div>
        </>
        )}
        {(router.query.section === "tac")&&(
          <>
          <div className="flex w-full">
          <LargeCard titleDescriptions = {[{title: "Terms of Service", description: "THESE TERMS OF SERVICE GOVERN YOUR USE OF OUR WEBSITE: 'HTTPS://WWW.INSURANCEAGENCYTRAINING.ORG'. BY ACCESSING OR USING OUR SITE, YOU AGREE TO BE BOUND BY THESE TERMS."}]}>
            <MarkdownRenderer markdown={tos_md}></MarkdownRenderer>
  
              </LargeCard>
          </div>
          </>
        )}
        {(router.query.section === "privacy")&&(
          <>
        <div className="flex w-full">
        <LargeCard titleDescriptions = {[{title: "Privacy Policy", description: "THIS PRIVACY POLICY EXPLAINS HOW WE COLLECT, USE, AND DISCLOSE PERSONAL INFORMATION OF USERS OF OUR WEBSITE: 'HTTPS://WWW.INSURANCEAGENCYTRAINING.ORG'"}]}>
          <MarkdownRenderer markdown={priv_pol_md}></MarkdownRenderer>

            </LargeCard>
        </div>
        </>
        )}
        {(router.query.section === "billing")&&(
          <>
          <SettingsBilling onStatus = {handleStatus}/>
        </>
        )}
        </div>
    </div>

      </div>
      <SideNav current = "settings"></SideNav>

    </>

  );
}

export default CustomSettingsSection;
