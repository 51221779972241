import React from "react"
import { useState,  } from "react"
import {  createSupportForm,  } from "../../util/db";
import LoadingIcon from "../LoadingIcon";
import { apiRequest } from "../../util/util";


export default function SupportForm() {
    const [buttonDisabled, setButtonDisabled] = useState(false)

    const [success, setSuccess] = useState(false)
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        body: "",
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
      };

    async function PostForm() {
        setButtonDisabled(true)

        await createSupportForm(
            {
                email: formData.email,
                name: formData.name,
                phone: formData.phone,
                body: formData.body
            }
        )
        await sendEmail()
        setSuccess(true)
        setButtonDisabled(false)
    }

    const sendEmail = async () =>  {
        setButtonDisabled(true)
        var message = `${formData.name}, ${formData.email}, ${formData.phone}: ${formData.body}`
        try {
          const data = {
                message: message,
                email: "ben@insuranceagencytraining.org",
                method: "Email",
                subject: "New Support Form",
                category: "help"
            };
    
            const response = await apiRequest('send-message', 'POST', data);
            console.log('Response after sending code:', response);
        } catch (error) {
          console.error("Error creating code: ", error)
        }
        setButtonDisabled(false)
      }

    return (
        <>
        <div className="flex flex-col items-center">
            {(!success)&&(
            <>
            <div className="w-full mx-auto bg-white p-6 shadow-md rounded-lg">
            <h2 className="text-2xl font-semibold mb-4">User Form</h2>
            <div className="flex lg:flex-row space-x-4">
            <div className="mb-4">
                <label className="block text-gray-700 font-medium mb-2" htmlFor="name">
                Name
                </label>
                <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                placeholder="Enter your name"
                />
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 font-medium mb-2" htmlFor="email">
                Email
                </label>
                <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                placeholder="Enter your email"
                />
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 font-medium mb-2" htmlFor="phone">
                Phone Number
                </label>
                <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                placeholder="Enter your phone number"
                />
            </div>
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 font-medium mb-2" htmlFor="body">
                Body Paragraph
                </label>
                <textarea
                id="body"
                name="body"
                value={formData.body}
                onChange={handleChange}
                className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                placeholder="Enter your message here"
                rows="4"
                ></textarea>
            </div>

            <div className="mt-4">
                <button
                className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300"
                onClick={() => PostForm()}
                >
                Submit
                </button>
            </div>
            </div>
            </>
            )}

        </div>
        {(success)&&(

        <div className="flex flex-col text-center space-y-4">
        <h2 className='text-5xl font-semibold'>Form Submitted</h2>
        <p className='text-lg font-semithin'>Thank you for reaching out to us, we will be in touch shortly to help you resolve this issue.</p>
        </div>
        )}

        {(buttonDisabled)&&<>
        <LoadingIcon className = 'h-6'/>
        </>}
        </>

    )
  }