import React from "react";
import Meta from "../../components/Meta";
import PublicHelpSection from "../../components/Custom/PublicHelpSection";

function PublicHelpPage(props) {
  return (
    <>
      <Meta title="Support" />
      <PublicHelpSection/>
    </>
  );
}

export default PublicHelpPage;
  