import React, { useState } from 'react';

const CompoundCalculator = () => {
    const [principal, setPrincipal] = useState(1000);
    const [rate, setRate] = useState(5);
    const [time, setTime] = useState(10);
    const [compoundsPerYear, setCompoundsPerYear] = useState(1);
    const [result, setResult] = useState(0);

    const calculateInterest = () => {
        const p = parseFloat(principal);
        const r = parseFloat(rate) / 100;
        const t = parseFloat(time);
        const n = parseFloat(compoundsPerYear);

        if (!isNaN(p) && !isNaN(r) && !isNaN(t) && !isNaN(n)) {
            const amount = p * Math.pow(1 + r / n, n * t);
            setResult(amount.toFixed(2));
        } else {
            setResult(0);
        }
    };

    return (
        <div className="bg-white p-8 my-2 rounded-lg border border-gray-300 w-full">
            <h1 className="text-2xl font-bold text-center mb-6 text-gray-800">Compound Interest Calculator</h1>
            <div className='flex flex-row space-x-2'>
            <div className="mb-2">
                <label className="block text-gray-700 mb-2">Principal Amount ($)</label>
                <input 
                    type="number" 
                    className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500" 
                    value={principal} 
                    onChange={(e) => setPrincipal(e.target.value)} 
                    placeholder="Enter principal amount"
                />
            </div>
            <div className="mb-2">
                <label className="block text-gray-700 mb-2">Annual Interest Rate (%)</label>
                <input 
                    type="number" 
                    className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500" 
                    value={rate} 
                    onChange={(e) => setRate(e.target.value)} 
                    placeholder="Enter interest rate"
                />
            </div>
            <div className="mb-2">
                <label className="block text-gray-700 mb-2">Time (Years)</label>
                <input 
                    type="number" 
                    className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500" 
                    value={time} 
                    onChange={(e) => setTime(e.target.value)} 
                    placeholder="Enter time in years"
                />
            </div>
            <div className="mb-2">
                <label className="block text-gray-700 mb-2">Compounds Per Year</label>
                <input 
                    type="number" 
                    className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500" 
                    value={compoundsPerYear} 
                    onChange={(e) => setCompoundsPerYear(e.target.value)} 
                    placeholder="Enter compounds per year"
                />
            </div>
            </div>
            <button 
                onClick={calculateInterest} 
                className="w-full bg-green-700 text-white p-3 rounded-md hover:bg-green-600 transition"
            >
                Calculate Interest
            </button>
            {result > 0 && (
                <div className="mt-6 p-4 bg-green-100 rounded-md text-center">
                    <p className="text-gray-700 font-semibold">Future Value: <span className="text-green-600 text-lg">${result}</span></p>
                </div>
            )}
        </div>
    );
};

export default CompoundCalculator;
