import React from "react";
import Section from "./Section";
import { Link } from "./../util/router";
import LargeCard from "./Custom/LargeCard";
import MarkdownRenderer from "./Custom/MarkDownRenderer";
import priv from "../assets/privacy_policy.md"
import  tos from "../assets/terms_of_service.md"

function LegalSection(props) {


  const validSections = {
    "terms-of-service": true,
    "privacy-policy": true,
  };

  const section = validSections[props.section]
    ? props.section
    : "terms-of-service";

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="container">
        <nav className="flex items-center border-b border-gray-200 max-w-sm mx-auto mb-12">
          {[
            { section: "terms-of-service", label: "Terms of Service" },
            { section: "privacy-policy", label: "Privacy Policy" },
          ].map((tab) => (
            <Link
              to={`/legal/${tab.section}`}
              className={
                "flex items-center grow justify-center px-3 md:px-4 -mb-px py-4 border-b-2" +
                (tab.section === section
                  ? " text-green-700 border-green-600"
                  : "") +
                (tab.section !== section
                  ? " border-transparent hover:text-green-700"
                  : "")
              }
              key={tab.section}
            >
              {tab.label}
            </Link>
          ))}
        </nav>

        {section === "terms-of-service" && <LargeCard titleDescriptions = {[{title: "Terms of Service", description: "THESE TERMS OF SERVICE GOVERN YOUR USE OF OUR WEBSITE: 'HTTPS://WWW.INSURANCEAGENCYTRAINING.ORG'. BY ACCESSING OR USING OUR SITE, YOU AGREE TO BE BOUND BY THESE TERMS."}]}>
        <MarkdownRenderer link={tos}></MarkdownRenderer>
            </LargeCard>}

        {section === "privacy-policy" && <LargeCard titleDescriptions = {[{title: "Privacy Policy", description: "THIS PRIVACY POLICY EXPLAINS HOW WE COLLECT, USE, AND DISCLOSE PERSONAL INFORMATION OF USERS OF OUR WEBSITE: 'HTTPS://WWW.INSURANCEAGENCYTRAINING.ORG'"}]}>
          <MarkdownRenderer link={priv}></MarkdownRenderer>
            </LargeCard>}
      </div>
    </Section>
  );
}

export default LegalSection;
