import { useUsersByIds } from "../../util/db"
import { Link } from "../../util/router"
import { PlusCircleIcon } from "@heroicons/react/24/outline"

  export default function ResourceCompletionTable({ids, videos}) {
    const {
        data: users,
        status: usersStatus,
        error: usersError
    } = useUsersByIds(ids)

    function checkCompletion(id) {
        var temp = 0
        users.forEach(each => {
            if (each.finishedContent && each.finishedContent.includes(id)) {
                temp +=1
            }
        })
        return temp
    }



    return (
        <div className="m-2">
      <ul className="">
        {(videos&&videos.length>0 && usersStatus==="success" && users && users.length>0)&&(
        <>
        <ul className="space-y-1 divide-y">
        {videos.map((video, index) =>
        <li key={index} className="overflow-hidden bg-white px-4 py-2 sm:px-6">
          <div className="grid grid-cols-2 space-x-2 items-center" key = {index}>
            <div className=" font-bold text-sm text-green-700">
            {video.title}
            </div>
            <div className="pr-2 sm:pr-0 w-full " aria-hidden="true">
                <div className='flex flex-row space-x-2 items-baseline'>
                <p className='font-semibold text-green-700'>{(checkCompletion(video.id)*100)/users.length}%</p>
                <p className='text-xs text-gray-500'>Completion Rate</p>

                </div>
                <div className="overflow-hidden rounded-full bg-gray-200">
                  <div className="h-2 rounded-full bg-green-700" style={{ width: `${(checkCompletion(video.id)*100)/users.length}%` }} />
                </div>
              </div>
            <div className="w-full text-center">
            {}
            </div>
            
          </div>
        </li>
        )}
        </ul>

          
        
        </>)}

        {(ids&& ids.length===0)&& (
           <>
           <div className="flex p-4 text-center align-center w-full justify-center">
               <div className="flex flex-col items-center">
               <p className="text-md font-semibold leading-6 text-gray-900">
                   Looks like you aren't in any teams...
               </p>
               <Link to ="/teams/all">
               <button
                  type="button"
                  className="relative flex flex-col items-center rounded-lg border border-dashed border-green-700 p-4 text-center hover:border-green-500 focus:outline-none text-green-700 hover:text-green-500 hover:bg-green-50"
              >
                  <PlusCircleIcon className="h-12 justify-center "/>
                  <span className="mt-2 text-sm font-semibold text-gray-900">Create A Team</span>
              </button>
              </Link>
               </div>
           </div>
           </>
        )}
      </ul>
        </div>

    )
  }
  