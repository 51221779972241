import React from 'react';
import VideoCard from './VideoCard';
import { useLiveWebinarById, useVideoById, usePDFsByCategory } from '../../util/db';
import { Link } from '../../util/router';
import { HomeIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { useRouter } from '../../util/router';
import { useAuth } from '../../util/auth';
import ValidatePaymentWrapper from './ValidatePaymentWrapper';
import PDFView from './PDFView';

const MemberViewSection = () => {
  const auth = useAuth()
  const router = useRouter()
  const getFirstElement = (array) => (array && array.length > 0 ? array[0] : null);

  const {
    data:videoEmbed,
    status:videoEmbedStatus,
    error:videoEmbedError
  } = useVideoById(router.query.index)

  const {
    data:liveVideoEmbed,
    status:liveVideoEmbedStatus,
    error:liveVideoEmbedError
  } = useLiveWebinarById(router.query.index)

  const category =
  videoEmbed?.linkedResources || liveVideoEmbed?.linkedResources || null;

  const {
    data: pdfs,
    status: pdfsStatus,
    error: pdfsError,
  } = usePDFsByCategory(getFirstElement(category));

  return (
    <>
    {(videoEmbedStatus === "success" && videoEmbed)&&(
    <div className='container'>
      <div className="pl-4 flex flex-row items-center pb-2">
              <Link to ="/dashboard/dashboard">
                <HomeIcon className="text-gray-400 h-6"/> 
              </Link>
              <ChevronRightIcon className="px-4 text-gray-400 h-6"/> 
              <Link to ="/tools/sp">
              <p className="text-sm text-gray-400">Sales Processes</p>
              </Link>
              <ChevronRightIcon className="px-4 text-gray-400 h-6"/> 
              <div>
                  <>
                  <p className="text-lg font-semibold">{videoEmbed.title}</p>
                  </>
              </div>
      </div>
      <ValidatePaymentWrapper requiredPlans={videoEmbed.requiredPlans}>
        <VideoCard title= {videoEmbed.subtitle} embedCode={videoEmbed.embed} description={videoEmbed.description} uid = {auth.user.uid} postId = {videoEmbed.id} prev={auth.user.finishedContent}/>
        {(pdfs && pdfsStatus === "success" && pdfs.length>0)&&(
          <>
          {pdfs.map((each, i) => <PDFView key = {i} pdf={each.embed}/>)}
          </>
        )}

      </ValidatePaymentWrapper>
    </div>
    )}

{(liveVideoEmbedStatus === "success" && liveVideoEmbed)&&(
    <div className='container'>
      <div className="pl-4 flex flex-row items-center pb-2">
              <Link to ="/dashboard/dashboard">
                <HomeIcon className="text-gray-400 h-6"/> 
              </Link>
              <ChevronRightIcon className="px-4 text-gray-400 h-6"/> 
              <Link to ="/tools/sp">
              <p className="text-sm text-gray-400">Sales Processes</p>
              </Link>
              <ChevronRightIcon className="px-4 text-gray-400 h-6"/> 
              <div>
                  <>
                  <p className="text-lg font-semibold">{liveVideoEmbed.title}</p>
                  </>
              </div>
      </div>
      <ValidatePaymentWrapper requiredPlans={liveVideoEmbed.requiredPlans} tags={liveVideoEmbed.tags}>
        <VideoCard title= {liveVideoEmbed.title} embedCode={liveVideoEmbed.embed} description={liveVideoEmbed.subtitle}/>
        {(pdfs && pdfsStatus === "success" && pdfs.length>0)&&(
          <>
          {pdfs.map((each, i) => <PDFView key = {i} pdf={each.embed}/>)}
          </>
        )}
      </ValidatePaymentWrapper>
    </div>
    )}
    </>
  );
};

export default MemberViewSection;
