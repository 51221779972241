
export default function CodeDetails({ codes }) {
    function isCodeRecent(createdAtSeconds) {
        const currentTimeSeconds = Math.floor(Date.now() / 1000);
        const oneMonthSeconds = 30 * 24 * 60 * 60; // Approximate seconds in one month
        return createdAtSeconds > currentTimeSeconds - oneMonthSeconds;
    }

    return (
        <div className="m-2">
            <ul>
                {codes && codes.length > 0 && (
                    <>
                        <ul className="space-y-1 divide-y">
                        <li   className="overflow-hidden bg-white px-4 py-2 sm:px-6">
                                    <div className="grid grid-cols-6 gap-4 items-center">
                                        <div className="font-bold text-sm text-green-700">
                                            Send Method
                                        </div>
                                        <div className="font-bold col-span-4 text-sm text-green-700">
                                            Recipient
                                        </div>
                                        <div className="font-bold text-sm text-green-700">
                                            Status
                                        </div>
                                    </div>
                                </li>
                            {codes.map((code, index) => (

                               isCodeRecent(code.createdAt.seconds)&&<li key={index} className="overflow-hidden bg-white px-4 py-2 sm:px-6">
                                    <div className="grid grid-cols-6 gap-4 items-center">
                                        <div className="text-sm text-gray-700">
                                            {code.method}
                                        </div>
                                        <div className=" col-span-4 space-x-4 text-sm text-gray-700">
                                        {Array.isArray(code.recipients) 
                                        ? code.recipients.join(", ") 
                                        : code.recipients}
                                        </div>
                                        <div className={`text-sm font-bold ${code.opened?"text-green-700":"text-red-700"}`}>
                                            {code.opened?"Opened":"Not Opened"}
                                        </div>

                                    </div>
                                </li>
                            ))}
                        </ul>
                    </>
                )}

                {codes && codes.length === 0 && (
                    <>
                        <div className="flex p-4 text-center align-center w-full justify-center">
                            <div className="flex flex-col items-center">
                                <p className="text-md font-semibold leading-6 text-gray-900">
                                    Looks like you haven't sent any video codes...
                                </p>
                            </div>
                        </div>
                    </>
                )}
            </ul>
        </div>
    );
}
