import React, { useEffect } from "react";
import Meta from "./../components/Meta";
import AuthSection from "./../components/AuthSection";
import image from "../assets/loginimage.png";
import logo from "../assets/whitelogo.png";
import { useRouter } from "./../util/router";
import { useAuth } from "../util/auth";

function AuthPage(props) {
  const router = useRouter();
  const auth = useAuth()
  useEffect(() => {
    if (auth?.user?.stripeSubscriptionStatus === "active" && router.query.expedited === "1") {
      router.replace("/settings/billing");
    }
  }, [auth?.user?.stripeSubscriptionStatus, router.query.expedited]); // Add dependencies

  function getAfterAuthPath() {
    if (router.query.expedited === '1') {
      return `/purchase/1-${router.query.plan}`
    } else {
      return router.query.type==="signup"? `/info/new${router.query.next?`?next=${router.query.next}`:""}`:router.query.next?router.query.next:"/tools/ip"
    }
  }

  return (
    <>
      <Meta title="Auth" />

      <div className="h-full flex flex-row">
        {/* Left Column */}
        <div className="flex-1 relative md:block hidden">
          {/* Image as the background */}
          <img src={image} alt="Background" className="absolute inset-0 w-full h-full object-cover" />

          {/* Overlay */}
          <div className="absolute inset-0 bg-green-900 bg-opacity-80"></div>

          {/* Logo and text centered overlay */}
          <div className="absolute inset-0 flex flex-col justify-center items-center">
            {/* Centered Logo */}
            <img src={logo} alt="Logo" className="w-96 mb-4" /> {/* Adjust the size and margin as needed */}

            {/* Centered Title and Text */}
            <div className="text-center text-white p-4">
              <h2 className="text-4xl font-bold">
                Revolutionize your insurance agency with our cutting-edge training features!
              </h2>
            </div>
          </div>
        </div>

        {/* Right Column */}
        <div className="flex-1 flex justify-center items-center">
          <AuthSection
            expedited = {(router.query.expedited==="1")}
            expeditedPlan = {router.query.plan}
            expeditedRef = {router.query.referee}
            size="md"
            bgColor="bg-white"
            bgImage=""
            bgImageOpacity={1}
            textColor=""
            type={router.query.type}
            // providers={["google"]}
            providers={""}

            afterAuthPath={getAfterAuthPath()}
          />
        </div>
      </div>
    </>
  );
}

export default AuthPage;
